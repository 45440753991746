import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import init_45global from "/app/middleware/init.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  init_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/app/middleware/admin.ts"),
  auth: () => import("/app/middleware/auth.ts"),
  "booking-operator": () => import("/app/middleware/bookingOperator.ts"),
  hidden: () => import("/app/middleware/hidden.ts"),
  "marketing-operator": () => import("/app/middleware/marketingOperator.ts"),
  operator: () => import("/app/middleware/operator.ts"),
  "settlement-operator": () => import("/app/middleware/settlementOperator.ts")
}